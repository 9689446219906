import axios from "axios";
import { JobDescriptionActions, } from "./enums";
import { useAsyncState } from "@vueuse/core"
import { useApiBaseUrl } from '~/composables/useApiBaseUrl'
export const uploadJobProfile = (file: any) => {
  return useAsyncState(axios.post(`${useApiBaseUrl()}/${JobDescriptionActions.JOB_Path}/${JobDescriptionActions.UPLOAD_PATH}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((t) => t.data),
    undefined);
};

export const createJobDescription = async (payload: any) => {
  return useAsyncState(axios.post(`${useApiBaseUrl()}/${JobDescriptionActions.JOB_Path}`, JSON.stringify(payload), {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((t) => t.data),
    undefined);
};

export const getAllJobDescriptions = async () => {
  return useAsyncState(
    axios.get(`${useApiBaseUrl()}/${JobDescriptionActions.JOBS_Path}`).then(t => t.data), undefined,
  )
};

export const getJobDescription = async (jobId: string) => {
  return useAsyncState(
    axios.get(`${useApiBaseUrl()}/${JobDescriptionActions.JOB_Path}/${jobId}`).then(t => t.data), undefined,
  )
};

export const generateQuestions = async (payload: any) => {
  return useAsyncState(axios.post(`${useApiBaseUrl()}/${JobDescriptionActions.QUESTIONS_PATH}`, JSON.stringify(payload), {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((t) => t.data),
    undefined);
};

export const updateJobDescription = async (payload: any, jobId: string) => {
  return useAsyncState(axios.put(`${useApiBaseUrl()}/${JobDescriptionActions.JOB_Path}/${jobId}`, JSON.stringify(payload), {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((t) => t.data),
    undefined);
};

export const deleteJob = async (jobId: string) => {
  return useAsyncState(
    axios.delete(`${useApiBaseUrl()}/${JobDescriptionActions.JOB_Path}/${jobId}`).then(t => t.data), undefined,
  )
};

export const updateJobStatus = async (jobId: string, status: string) => {
  return useAsyncState(
    axios.patch(`${useApiBaseUrl()}/${JobDescriptionActions.JOB_Path}/${jobId}/${JobDescriptionActions.STATUS_PATH}/${status}`).then(t => t.data), undefined,
  )
};

export const getJobTitles = async () => {
  return useAsyncState(
    axios.patch(`${useApiBaseUrl()}/${JobDescriptionActions.JOB_TITLES_PATH}`).then(t => t.data), undefined,
  )
};
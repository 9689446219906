import { defineStore } from 'pinia'
import { getAllJobDescriptions, uploadJobProfile, createJobDescription, generateQuestions, updateJobDescription, deleteJob, updateJobStatus } from '@/api/job'
import { type Job } from '@/types/job'
export const useMyJobStore = defineStore({
  id: 'myJobStore',
  state: () => ({
    jobDescriptions: [] as Job[],
    timezones: [
      { "name": "India Standard Time", "value": "+0530", "country": "India" },
      { "name": "Eastern Standard Time", "value": "-0500", "country": "United States" },
      { "name": "Central Standard Time", "value": "-0600", "country": "United States" },
      { "name": "Mountain Standard Time", "value": "-0700", "country": "United States" },
      { "name": "Pacific Standard Time", "value": "-0800", "country": "United States" },
      { "name": "Alaska Standard Time", "value": "-0900", "country": "United States" },
      { "name": "Hawaii-Aleutian Standard Time", "value": "-1000", "country": "United States" }],
    countries: [
      "India",
      "United States",
    ]
  }),
  actions: {
    uploadJobProfile(file: FormData) {
      return uploadJobProfile(file)
    },
    async getAllJobDescriptions() {
      const { state, error } = await getAllJobDescriptions()
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error fetching job descriptions" }
      } else {
        this.jobDescriptions = state.value?.data
        return { status: 200, data: state.value?.data }
      }
    },
    async generateQuestions(payload: any) {

      const { state, error } = await generateQuestions(payload)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error generating questions" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
    async createJobDescription(payload: any) {
      const { state, error } = await createJobDescription(payload)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error creating job description" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
    async updateJobDescription(payload: any, jobId: string) {
      const { state, error } = await updateJobDescription(payload, jobId)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error updating job description" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
    async updateJobStatus(jobId: string, status: string) {
      const { state, error } = await updateJobStatus(jobId, status)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error updating job status" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
    async deleteJob(jobId: string) {
      const { state, error } = await deleteJob(jobId)
      if (error.value) {
        return { status: error.value ? error.value.status : 500, error: "Error deleting job" }
      } else {
        return { status: 200, data: state.value?.data }
      }
    },
  }
})
